/**
 * Site header
 */
.mobile-header {
  display: none;
  position: sticky !important;
  padding: $spacing-unit/3 0;

  @include media-query($on-laptop) {
    display: flow;
  }

  nav {
    margin-top: $spacing-unit/3;
    font-size: 1.5rem;
    background: $white-color;
    position: absolute;
    width: 100%;
    font-family: "BalooBhaijaan2", Arial, Helvetica, sans-serif;
  }

  nav a {
    display: block;
  }
}

.header-nav {
  text-align: left;
}

.logo-level {
  position: relative;
  margin: $spacing-unit/3 $spacing-unit;

  // display: flex;
  // justify-content: space-between;
  span {
    position: absolute;
    bottom: 15%;
    right: 0;
    display: flex;
    gap: 15px;
  }
}

.header-nav-toplevel {
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  text-align: center;

  span {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

.header-nav-bottomlevel {
  display: flex;
  gap: 20px;
}

.active-header-link {
  color: $brand-blue;
}

.mobile-menu-icon {
  position: absolute;
  right: 10px;
  top: 25px;
  cursor: pointer;
  display: inline-block;
}

.marquee {
  --gap: .5rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  padding: 5px 0;

  ul {
    margin: 0;
    padding: 0;
  }

  &:hover {
    .marquee__content {
      animation-play-state: paused;
    }
  }
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 40s linear infinite;
  animation-direction: normal;
  list-style-type: none;
  text-transform: uppercase;
  align-items: center;
  font-family: "bd-viewmaster", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  color: $green-dark;

  img {
    width: 90px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__content {
    animation-play-state: paused !important;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.navicon {
  background-color: $brand-blue;
  display: inline-block;
  position: relative;
  width: 35px;
  height: 2px;
  cursor: pointer;

  &:before {
    top: 12px;
    content: "";
    background-color: $brand-blue;
    display: block;
    position: absolute;
    width: 35px;
    height: 2px;
    cursor: pointer;
  }

  &:after {
    bottom: 12px;
    content: "";
    background-color: $brand-blue;
    display: block;
    position: relative;
    width: 35px;
    height: 2px;
    cursor: pointer;
  }
}

.navicon:before,
.navicon:after {
  transition: all 0.3s ease-out;
}

#mobile-menu-btn {
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.mobile-menu {
  overflow: hidden;
  height: 100vh;
  pointer-events: none;
  transition: all 100ms ease-in-out;
  transform: scaleY(0);
  transform-origin: 50% 0%;
}

.mobile-menu-btn:checked~.mobile-menu {
  display: flow;
  pointer-events: initial;
  transform: scaleY(100%);
}

.mobile-menu-btn:checked~.mobile-menu-icon .navicon {
  background: transparent;
}

.mobile-menu-btn:checked~.mobile-menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.mobile-menu-btn:checked~.mobile-menu-icon .navicon:after {
  transform: rotate(45deg);
}

.mobile-menu-btn:checked~.mobile-menu-icon .navicon:before,
.mobile-menu-btn:checked~.mobile-menu-icon .navicon:after {
  top: 0;
}

.mobile-social-icons {
  margin-top: $spacing-unit;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.mobile-social-icons .svg-icon {
  fill: $brand-blue;
}

.desktop-header {
  display: flow;

  @include media-query($on-laptop) {
    display: none
  }
}


.site-header {
  text-align: center;
  min-height: $spacing-unit * 1.865;
  position: sticky;
  top: 0;
  background-color: $white-color;
  z-index: 500;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  transition: top .4s ease-in-out;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: 0;
    padding-left: 0;
  }

  >div {
    padding: $spacing-unit/2 0;

    @include media-query($on-laptop) {
      padding: 0;
    }
  }

  .svg-icon {
    fill: $brand-color;

    &:hover {
      fill: $brand-color;
    }
  }
}

.site-header-promo {
  background-color: $brand-color;

  >div {
    padding: $spacing-unit/3 0;
  }
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: inherit;
  }
}

.nav {
  display: flex;
  justify-content: center;
  gap: 10px;

  a {
    display: inline-block;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  display: flex;
  gap: 20px;
  padding: 10px 0;
}

.header-icon-image {
  height: 110px;
  margin-bottom: 10px;
}

.mobile-header-icon-image {
  height: 120px;
  padding: 5px;
}

.site-footer {
  position: relative;
}

.body-content {
  position: relative;
  z-index: 5;
}

.site-bg {
  background-image: url("/assets/img/01-Lizard-Pattern-F.jpg");
  background-size: 250px;
  opacity: 0.25;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  @include media-query($on-laptop) {
    display: block;
  }
}

.footer-main-right {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include media-query($on-laptop) {
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
  }
}

.footer-main-right-links {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-column-gap: 20px;
  display: flex;
  gap: 20px;
}

.footer-main-right-social {
  display: flex;
  gap: 15px;
  justify-content: flex-end;

  @include media-query($on-laptop) {
    justify-content: flex-start;
  }
}

.business-name-banner {
  background-color: $brand-color-light;
  padding: $spacing-unit;
  position: relative;

  svg {
    fill: #FFF;
  }
}

.business-name-banner-contents {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  color: #FFF;
  font-size: 2rem;
  align-items: center;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.extra-ctas {
  margin-top: $spacing-unit/2;
  display: flex;
  justify-content: space-between;
}

#mc_embed_signup form {
  margin: 0 !important;
}

#mce-EMAIL {
  border: 0 !important;
  border-bottom: 2px solid $brand-blue-light !important;
  border-radius: 0 !important;
  background-color: transparent !important;

  &:focus {
    border: 0 !important;
    border-bottom: 2px solid $brand-blue !important;
    outline: none !important;
  }
}

#mce-EMAIL.mce_inline_error {
  border-bottom: 2px solid #de6a63 !important;
}

#mc_embed_signup {
  background: transparent;
  clear: left;
  display: flex;
  align-items: flex-start;
  padding-bottom: 0 !important;
}

.mc-field-group {
  width: 100% !important;
}

#mc_embed_signup .button {
  font-family: "BalooBhaijaan2", Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  padding-top: 2px !important;
  background-color: $brand-blue-light !important;
  color: #f4edd1 !important;

  &:hover {
    background-color: $brand-blue !important;
  }
}

#mc_embed_tagline {
  font-size: .8rem;
  margin-bottom: .5rem;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  color: #de6a63 !important;
  background-color: transparent !important;
}

.extra-links-list {
  list-style: none;
  font-size: .8rem;
}

/**
 * Page content
 */
.page-content {
  padding: 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  >li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.page-banner-container {
  // padding: 0 400px;

  @include media-query($on-laptop) {
    // padding: 0 $spacing-unit;
  }
}

.page-banner-img {
  width: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
  @include media-query($on-laptop) {
    padding: 0 0;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background-color: $white-color !important;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);
    letter-spacing: .5px;

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.page-section {
  padding: $spacing-unit $spacing-unit;

  @include media-query($on-laptop) {
    padding: 10px;
  }
}

.section-header {
  text-align: center;
}

#shop-alternate-banner {
  text-align: center;
  display: none;

  img {
    width: 100%;
  }
}

.show-banner {
  display: block !important;
}

.loading-icon {
  text-align: center;
  margin-bottom: $spacing-unit;
}

.loading-icon-alt {
  width: 100px;
  animation: loadinganim 1s linear infinite;
}

@keyframes loadinganim {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  15% {
    transform: translateY(5px) rotate(-7deg);
  }

  50% {
    transform: translateY(-25px) rotate(7deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.product-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-columns: 33%;
  gap: 20px;

  @include media-query($on-laptop) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
}

.product-item {
  text-align: center;
  position: relative;
  width: 100%;

  img {
    max-height: 350px;

    @include media-query($on-laptop) {
      max-height: initial;
    }
  }

  &:hover {
    .product-item-wrapper {
      bottom: 10px;
    }
  }
}

.product-item-wrapper {
  transition: all 100ms ease-in-out;
  position: relative;
  bottom: 0;
}

.product-list-item-thumb-name {
  word-break: break-word;
  position: relative;
  padding: 0 10px;
}

.product-list-item-thumb-name:after {
  bottom: -8px;
  content: "";
  background-color: currentColor;
  height: 1px;
  left: 50%;
  opacity: 0.5;
  position: absolute;
  transform: translateX(-50%);
  width: 16px;
}

.product-list-item-price {
  margin-top: 12px;

  &:before {
    content: '$';
  }
}

.sold-out-sticker {
  color: $white-color;
  position: absolute;
  right: 10%;
  width: 100px;
  line-height: 100px;
  font-weight: bold;

  @include media-query($on-laptop) {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    line-height: 120px;
    font-size: 24px;
  }

  z-index: 2;
}

.character-promos {
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 20px));
  gap: 20px;
  margin-top: $spacing-unit / 2;

  @include media-query($on-laptop) {
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap: 10px;
  }
}

.character-promo {
  font-size: 1.5rem;
  letter-spacing: .5px;

  &:hover {
    img {
      transform: translateY(-10px);
    }

    .character-promo-name {
      color: $brand-blue-light;
    }
  }
}

.character-promo-box {
  width: 100%;
  aspect-ratio: 1/1;

  @include media-query($on-laptop) {
    width: 100%;
  }

  img {
    transform: translateY(0px);
    transition: all 100ms ease-in-out;
    height: 100%;
    width: 100%;
  }
}

.character-promo-name {
  font-weight: 600;
  font-family: "BalooBhaijaan2", Arial, Helvetica, sans-serif;
  color: $brand-blue;
  text-align: center;
}

.character-nav {
  position: sticky;
  z-index: 5;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: $spacing-unit/2;
  background-color: rgba(255, 255, 255, .85);
  bottom: 0px;
  display: flex;
  gap: 35px;
  user-select: none;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  transition: gap $anim-fast linear;

  @include media-query($on-laptop) {
    gap: 20px;
  }
}


.character-nav-icon {
  display: inline-block;
  flex-shrink: 0;
  width: 85px;

  img {
    width: 100%;
    -webkit-user-drag: none;
    pointer-events: none;
  }

  &:hover {
    img {
      animation: charactericon 200ms ease-in-out;
      transform-origin: 50% 70%;
    }
  }
}

.character-icon-a {
  animation: charactericon_idle_a 1s infinite;
}

.character-icon-b {
  animation: charactericon_idle_b 1s infinite;
}

@keyframes charactericon_idle_a {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 2px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes charactericon_idle_b {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -2px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes charactericon {
  0% {
    transform: rotate(4deg) translate(0, 2px);
  }

  50% {
    transform: rotate(-4deg) translate(0, -2px);
  }

  100% {
    transform: rotate(0) translate(0, 0);
  }
}

.character-story-container {
  padding-top: 10px;
  display: flex;

  @include media-query($on-laptop) {
    display: initial;
  }
}

.character-story-title {
  font-family: "BalooBhaijaan2", Arial, Helvetica, sans-serif;
  font-size: 80px;
  font-weight: 600;
  line-height: 4.8rem;
  color: #f4eed7;
  margin-top: $spacing-unit * 2;

  @include media-query($on-laptop) {
    margin-top: $spacing-unit;
  }
}

.character-story-text {
  flex-basis: 60%;
}

.character-story-img {
  width: 300px;
  position: relative;
  right: 10px;
  align-self: center;

  @include media-query($on-laptop) {
    right: 0;
    width: 100%;
  }
}

.character-story-social {
  margin-top: 140px;
  flex-basis: 250px;
  font-weight: 600;
  line-height: .8rem;
  color: $brand-blue;

  @include media-query($on-laptop) {
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
  }
}

.character-social-media-list {
  display: flex;
  gap: 10px;

  .svg-icon {
    fill: $brand-blue;
  }
}

.about-container {
  display: flex;

  @include media-query($on-laptop) {
    display: initial;
  }
}

.about-img {
  width: 300px;
  position: relative;
  right: 30px;
  margin-top: -15px;

  @include media-query($on-laptop) {
    margin-top: 0;
    right: 0;
  }
}

.oh-no-no-product {
  text-align: center;

  img {
    max-width: 600px;

    @include media-query($on-laptop) {
      max-width: 100%;
    }
  }
}